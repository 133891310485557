<template>
  <div id="app">
    <PageHeader />
    <main>
      <router-view></router-view>
    </main>
    <PageFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PageHeader from './components/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';

export default defineComponent({
  components: {
    PageHeader,
    PageFooter
  },
});
</script>

<style scoped>
#app {
  font-family: Jost, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow-x: hidden;
}
</style>
