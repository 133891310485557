<template>
  <div id="app" class="flex flex-col h-screen w-full max-w-7xl mx-auto bg-white shadow-lg relative -mb-8">
    <!-- Profile Section -->
    <div class="bg-white shadow-sm flex items-center p-4 sticky top-0 z-10">
      <div class="flex items-center space-x-4 w-full">
        <img src="@/assets/teacher-man-2.jpg" alt="Profile Image" class="w-20 h-20 rounded-full object-cover">
        <div>
          <h2 class="text-lg font-semibold">Mustafa Öztürk</h2>
          <p class="text-gray-500 text-sm">Matematik Öğretmeni</p>
        </div>
      </div>
      <div class="text-gray-800 p-4 flex sm:text-right sm:flex-1 justify-end">
      <button @click="openSettingsDialog" class="focus:outline-none">
        <component :is="Cog6ToothIcon" class="h-6 w-6 text-gray-800 hover:text-indigo-600" aria-hidden="true" />
      </button>
    </div>
    </div>
    <!-- Chat Window -->
    <div class="flex-1 p-4 overflow-y-auto space-y-4 scrollbar-hide" ref="chatWindowRef">
      <div v-for="(message, index) in messages" :key="index" :class="['message', message.sender]">
        <!-- User Message -->
        <div v-if="message.sender === 'user'" class="flex justify-end">
          <div class="bg-indigo-500 text-white p-3 rounded-l-lg rounded-br-lg max-w-sm shadow-md">
            <p class="text-sm">{{ message.text }}</p>
          </div>
        </div>
        <!-- Bot Message -->
        <div v-else class="flex justify-start">
          <div class="bg-gray-100 p-3 rounded-r-lg rounded-bl-lg max-w-sm shadow-md">
            <p class="text-sm">{{ message.text }}</p>
          </div>
        </div>
      </div>
      <!-- Loading Indicator -->
      <div class="flex justify-center my-4">
        <VueSpinnerDots v-if="isWaitingResponse" size="32" class="text-gray-400" />
      </div>
    </div>
    <!-- Input Container -->
    <div class="bg-gray-100 p-4 border-t border-gray-200 flex items-center space-x-4 fixed bottom-0 w-full max-w-7xl mx-auto">
      <input v-model="question" placeholder="Mesajınız..."
        class="flex-grow p-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        @keyup.enter="sendMessage" />
      <button @click="sendMessage" class="bg-gray-900 text-white px-6 py-3 rounded-full shadow-md hover:bg-blue-600">
        Gönder
      </button>
    </div>
    <!-- Settings Dialog -->
    <div v-if="showSettingsDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h2 class="text-xl font-semibold mb-4">Settings</h2>
        <p class="mb-4">
          <button @click="createThread" :disabled="isRecording" class="bg-gray-900 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600">Create Thread</button>
          {{ threadId }}
        </p>
        <button @click="closeSettingsDialog" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, nextTick, watch, onMounted } from 'vue';
import { Cog6ToothIcon} from '@heroicons/vue/24/outline'
import { VueSpinnerDots} from 'vue3-spinners';
import axios from 'axios';

interface Message {
  text: string;
  sender: string;
}

export default defineComponent({
  name: 'ChatAssistant',
  components: {
    Cog6ToothIcon,
    VueSpinnerDots
  },
  setup() {
    const audioData: Ref<Blob | null> = ref(null);
    const audioSrc: Ref<string> = ref('');
    const analyser: Ref<AnalyserNode | null> = ref(null);
    const audioElement: Ref<HTMLAudioElement | null> = ref(null);
    const isWaitingResponse: Ref<boolean> = ref(false);
    const threadId: Ref<string> = ref('thread_z8QGwSzUzeVuPE2KMKvWF1p4');
    const question: Ref<string> = ref('');
    const messages: Ref<Message[]> = ref([]);
    const typingMessageIndex: Ref<number | null> = ref(null);
    const isRecording: Ref<boolean> = ref(false);
    const chatWindowRef: Ref<HTMLElement | null> = ref(null);
    const showSettingsDialog = ref(false);

    const createThread = async () => {
      try {
        console.log("call")
        const response = await axios.get('http://localhost:3000/thread');
        console.log('Received response from backend', response.data.threadId);
        threadId.value = response.data.threadId;
      } catch (error) {
        console.error('Error creating thread:', error);
      }
    };

    const sendMessage = async () => {
      
      if (question.value.trim() === '') return;

      messages.value.push({ text: question.value, sender: 'user' });
      isWaitingResponse.value = true;
      scrollToBottom();
      startStream(threadId.value);
    };

    const startStream = (threadId: string) => {

      const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_VERCEL_API_URL
        : process.env.VUE_APP_LOCAL_API_URL;
      console.log(`${apiUrl}stream/${threadId}/${encodeURIComponent(question.value)}`);
      console.log("deneme")
      const eventSource = new EventSource(`${apiUrl}stream/${threadId}/${encodeURIComponent(question.value)}`);
      question.value = '';
      //console.log("start stream");
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.message === 'completed') {
          typingMessageIndex.value = null;
          return;
        }

        if (data.message) {
          isWaitingResponse.value = false;
          handleIncomingMessage(data.message);
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        //("hata var");
        eventSource.close();
      };
    };

    const handleIncomingMessage = (text: string) => {
      if (typingMessageIndex.value === null) {
        messages.value.push({ text: text, sender: 'bot' });
        typingMessageIndex.value = messages.value.length - 1;
      } else {
        messages.value[typingMessageIndex.value].text += text;
      }
      scrollToBottom();
    };

    const scrollToBottom = () => {
      nextTick(() => {
        const chatWindow = chatWindowRef.value as HTMLElement;
        if (chatWindow) {
          chatWindow.scrollTop = chatWindow.scrollHeight;
        }
      });
    };

    const sendAudio = async () => {
      if (question.value.trim() === '') return;

      if (!audioData.value) {
        return;
      }

      messages.value.push({ text: question.value, sender: 'user' });
      isWaitingResponse.value = true;

      const formData = new FormData();
      formData.append('question', question.value);
      formData.append('threadId', threadId.value);

      //console.log('Sending audio data to backend');
      //console.log(threadId.value);

      try {
        const response = await axios.post('http://localhost:3000/message', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log('Received response from backend', response);
        messages.value.push({ text: response.data.message, sender: 'bot' });
        audioSrc.value = response.data.audio;

        console.log('new audio:' + audioSrc.value);
      } catch (error) {
        console.error('Error sending audio:', error);
      }
      isWaitingResponse.value = false;
    };

    const speakResponse = (text: string) => {
      const msg = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(msg);
    };

    const isLastMessage = (index: number) => {
      return index === messages.value.length - 1;
    };

    const openSettingsDialog = () => {
      showSettingsDialog.value = true;
    };

    const closeSettingsDialog = () => {
      showSettingsDialog.value = false;
    };

    onMounted(() => {
   //   scrollToBottom();
     // messages.value.push({ text: "__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle. __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle. __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle. __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle. __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle. __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle. __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ is not explicitly defined. You are running the esm-bundler build of Vue, which expects these compile-time feature flags to be globally injected via the bundler config in order to get better tree-shaking in the production bundle.", sender: 'bot' });
      scrollToBottom();
      watch(audioSrc, (newSrc) => {
        if (newSrc) {
          const ses = new Audio(newSrc);
          ses.play();
        }
      });
    });

    return {
      audioData,
      audioSrc,
      analyser,
      audioElement,
      isWaitingResponse,
      threadId,
      question,
      messages,
      typingMessageIndex,
      isRecording,
      chatWindowRef,
      createThread,
      sendMessage,
      startStream,
      handleIncomingMessage,
      scrollToBottom,
      sendAudio,
      speakResponse,
      isLastMessage,
      showSettingsDialog,
      openSettingsDialog,
      closeSettingsDialog,
      Cog6ToothIcon
    };
  }
});
</script>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* Internet Explorer */
  scrollbar-width: none;
  /* Firefox */
}
</style>
