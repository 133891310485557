<template>

  <section class="pt-24 overflow-hidden">
    <div class="container mx-auto px-4">
      <img class="mx-auto mb-8 h-10 md:mb-16 md:h-16" src="@/assets/logo-footer.png" alt="">
      <!---
    <ul class="flex flex-wrap justify-center -m-5 pb-8">
      <li class="p-5"><a class="font-heading text-base text-gray-900 hover:text-gray-700" href="#">Features</a></li>
      <li class="p-5"><a class="font-heading text-base text-gray-900 hover:text-gray-700" href="#">Pricing</a></li>
      <li class="p-5"><a class="font-heading text-base text-gray-900 hover:text-gray-700" href="#">Affiliate Program</a></li>
      <li class="p-5"><a class="font-heading text-base text-gray-900 hover:text-gray-700" href="#">Press Kit</a></li>
    </ul>
    -->
      <div class="flex justify-center items-center h-full pb-6">
        <a href="https://www.linkedin.com/company/equity-road-ventures" target="_blank" class="[&>svg]:h-5 [&>svg]:w-5  text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path
              d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
          </svg>
        </a>
      </div>
      <div class="border-b border-gray-100"></div>
      <p class="text-gray-600 text-center py-8 text-sm">© Copyright 2024. All Rights Reserved by ERV TEKNOLOJİ A.Ş.
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth';

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const isOpen = ref(false);

    const logout = () => {
      authStore.logout();
      router.push('/');
    };

    router.afterEach(() => {
      isOpen.value = false;
    });

    return {
      authStore,
      isOpen,
      logout,
    };
  },
});
</script>

<style scoped>
/* Stil tanımları */
</style>